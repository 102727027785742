import { Provider } from '@vgtv/api-client';
import type { SvpAssetAdditional } from '@vgtv/api-client/lib/svp_asset';
import type { Config } from '@schibsted-svp/web-player';
import type { DeepPartial } from '@reduxjs/toolkit';

import { config } from '../config';
import {
  getIdentityInstance,
  getMonetizationInstance,
  login,
} from '../services/schibstedAccount';
import { getPreviousPageViewOrigin } from '../services/tracking/helpers/pulseOrigin';
import { hasAdblock } from '../utils/hasAdblock';
import type { PulseEvent } from '../services/tracking/pulse';
import { isMobile } from '../utils/device';
import { getAssetPagePathV2 } from '../shared/helpers/getAssetPagePath';
import type { Vendor } from '../types';
import { getPulseProvider } from '../services/tracking/helpers/pulseProvider';
import { unleash } from '../services/unleash';

export const PLAYER_ELEMENT_ID = 'player';

export const PLAYER_DEFAULTS = {
  aab: config.provider === Provider.VGTV ? hasAdblock() || 'auto' : false,
  aabConfig: 'https://vgc.no/cdn/js/libs/homad-config.json',
  locale: config.provider === Provider.AB ? 'se' : 'no',
} as const;

const experiments: {
  id: string;
  variant: string;
}[] = [{ id: 'error-reporting', variant: 'enabled' }];

if (config.featureToggles.enableAdsDynamicAspectRatio) {
  experiments.push({
    id: 'ads-aspect-ratio',
    variant: isMobile ? 'true' : 'false',
  });
}

experiments.push(
  ...unleash
    .getAllToggles()
    .filter(({ type }) => type === 'experiment')
    .map(({ name, variant }) => ({
      id: name,
      variant: variant.name,
    }))
);

export const SITE_OPTIONS = {
  site: config.provider === Provider.VGTV ? 'vgtv' : 'abtv',
  context: 'portal',
  ads: {
    pausead: true,
    midroll: true,
  },
  experiments,
  paywallAccess: {
    enabled: true,
    getSpidIdentityInstance: getIdentityInstance,
    getSpidMonetizationInstance: getMonetizationInstance,
    usePre: config.schibstedAccount.env === 'PRE',
    message: {
      onLoginClick: login,
    },
  },
} as const;

export const SITE_CONFIG: DeepPartial<Config> = {
  vendor: config.provider,
  locale: config.provider === Provider.AB ? 'se' : 'no',
  node: PLAYER_ELEMENT_ID,
  autoplay: false,
  settings: {},
  pulse: {
    provider: config.pulse.site,
    // @ts-expect-error invalid typedef
    decorator(
      data: PulseEvent,
      model: { getRawAsset: () => SvpAssetAdditional }
    ) {
      const decorated = { ...data };
      const asset = model.getRawAsset();

      const vendor = (asset.provider || config.provider) as Vendor;

      decorated.provider = getPulseProvider(vendor);

      decorated.object!.page = {
        '@id': `sdrn:${
          vendor === 'brandstudio' ? vendor : config.pulse.site
        }:video:${asset.id}`,
        '@type': config.provider === Provider.AB ? 'Article' : 'Video',
        url: `${window.location.origin}${getAssetPagePathV2({
          asset,
          skipDeeplinkCheck: true,
        })}`,
      };

      decorated.origin = getPreviousPageViewOrigin();
      return decorated;
    },
  },
  plugins:
    config.provider === Provider.AB
      ? {
          'https://vgc.no/player/player-plugin-sponsor-overlay-latest.js': {
            name: 'SponsorOverlayPlugin',
          },
        }
      : undefined,
  showSponsorLabel: true,
  skin: {
    name: 'vgtv2',
    url: 'https://vgc.no/player/player-skin-vgtv2-latest.css',
  },
};

if (config.provider === Provider.AB) {
  SITE_CONFIG.settings!.jw = {
    advertising: {
      autoplayadsmuted: true,
    },
  };
}

if (process.env.NODE_ENV === 'development') {
  SITE_CONFIG.settings!.na = config.disablePlayerAds;
  if (config.svpApiHostname.includes('svp-stage')) {
    SITE_CONFIG.thumbnailsUrl = 'https://svp-stage.vg.no/svp/thumbnails/v1/';
    SITE_CONFIG.relatedUrl = 'https://related-stage.stream.schibsted.media/';
    SITE_CONFIG.api = 'https://svp-stage.vg.no/svp/api/v1/';
    SITE_CONFIG.tokenUrl = 'https://svp-stage.vg.no/svp/token/v1/';
  }
}
